<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <div class="table-header m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mr-1 mb-0">Show</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :value="perPage"
              :clearable="false"
              :reduce="option => option.value"
              class="per-page-selector d-inline-block ml-50 mr-1"
              @input="updatePerPage"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                v-if="$can('create', permissionSubjects.Tasks)"
                variant="primary"
                class="mr-2"
                @click="showCreateTaskModal"
              >
                Create Task
              </b-button>
              <label class="mr-1 mb-0">Search</label>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                class="w-25"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refInvoiceListTable"
        :items="fetchTaskList"
        :fields="tableColumns"
        primary-key="id"
        show-empty
        sticky-header="75vh"
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative table-sticky-head"
      >

        <!-- Loader -->
        <template #table-busy>
          <TableSpinner />
        </template>

        <!-- Column: task_name -->
        <template #cell(task_name)="data">
          <b-link
            :to="{ name: 'application-tasks', params: { taskId: data.item.id, taskName: data.item.name } }"
          >{{ data.item.name }}</b-link>
        </template>

        <!-- Column: completed_forms -->
        <template #cell(completed_forms)="data">
          <span>{{ data.item.completed_tasks }} / {{ data.item.total_tasks }}</span>
        </template>

        <!-- Column: program_name -->
        <template #cell(program_name)="data">
          <span>{{ data.item.program.name }}</span>
        </template>

        <!-- Column: due_date -->
        <template #cell(due_date)="data">
          <span>{{ data.item.date | shortDate }}</span>
        </template>

        <!-- Column: actions -->
        <template #cell(actions)="data">
          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="mx-auto p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="$can('edit', permissionSubjects.Tasks)"
              @click="showEditTaskModal(data.item)"
            >
              <span>Edit</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$can('delete', permissionSubjects.Tasks)"
              @click="deleteTask(data.item.id)"
            >
              <span>Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="totalItems && perPage !== 'all'"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card>

    <new-task-modal
      :is-task-editing="isTaskEditing"
      :editable-task="editableTask"
      @success="refetchData"
    />
  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton,
  BTable, BPagination, BLink, BDropdownItem, BDropdown,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import { filters } from '@core/mixins/filters'

import vSelect from 'vue-select'
import store from '@/store'
import storeModule from '@/views/admin/tasks/taskStoreModule'
import useTaskList from '@/views/admin/tasks/task-list/useTaskList'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import NewTaskModal from '@/views/components/new-task-modal/NewTaskModal.vue'
import { DEFAULT_PER_PAGE } from '@/constants/base'
import setPerPage from '@/helpers/setPerPage'
import { permissionSubjects } from '@/libs/acl/constants'

export default {
  name: 'TaskList',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BLink,
    BDropdownItem,
    BDropdown,

    vSelect,

    TableSpinner,
    NewTaskModal,
  },
  mixins: [filters],
  data() {
    return {
      fieldProgramPlaceholder: 'Loading...',
      isTaskEditing: false,
      editableTask: null,
      permissionSubjects,
    }
  },
  setup(props, { root }) {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-tasks'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchTaskList,
      tableColumns,
      perPage,
      perPageOptions,
      currentPage,
      totalItems,
      searchQuery,
      sortBy,
      dataMeta,
      isSortDirDesc,
      refInvoiceListTable,
      refetchData,
      clearFilters,
      deleteTask,
    } = useTaskList(root)

    return {
      fetchTaskList,
      tableColumns,
      perPage,
      perPageOptions,
      currentPage,
      totalItems,
      searchQuery,
      sortBy,
      dataMeta,
      isSortDirDesc,
      refInvoiceListTable,
      refetchData,
      clearFilters,
      deleteTask,
    }
  },
  watch: {
    $route() {
      if (!this.$route.query.currentPage) {
        this.setFilterParams()
      }
      this.refetchData()
    },
  },
  async created() {
    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
  },
  methods: {
    updatePerPage(val) {
      localStorage.setItem('tasksPerPage', val)
      this.perPage = val
    },
    setFilterParams() {
      const query = { ...this.$route.query }
      const defaultPerPage = localStorage.getItem('tasksPerPage')
      this.currentPage = Number(query.currentPage) || 1
      this.perPage = setPerPage(query.perPage, defaultPerPage, DEFAULT_PER_PAGE)
      this.searchQuery = query.searchQuery || ''
    },
    showEditTaskModal(item) {
      this.isTaskEditing = true
      this.editableTask = item
      this.$nextTick(() => {
        this.$bvModal.show('new-task-modal')
      })
    },
    showCreateTaskModal() {
      this.isTaskEditing = false
      this.$nextTick(() => {
        this.$bvModal.show('new-task-modal')
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>
